@import "./../../../app/theme.less";

.widget {
  .product_item {
    width: 100%;
  }

  &__filter_bar {
    &--left {
      display: flex;
      justify-content: space-between;

      @media (max-width: @screen-md) {
        display: block;
      }

      .filter_switch {
        margin-left: 8px;
      }
    }
  }
}

.products {
  .widget-search-bar {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }

  &__body {
    &__selected {
      height: auto !important;
    }

    .infinite-scroll-component {
      overflow: hidden !important;
    }
  }
}

.rangePrice {
  .ant-input-number-group-wrapper {
    width: 100% !important;
  }
}

.sliderMarksWrapper {
  display: flex;
  justify-content: space-between;
  color: @gray-new;
}

.filterTagsWrapper {
  line-height: 35px;
}

.footer-sticky {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: @sticky-footer;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: space-between;

  @media (max-width: @screen-md) {
    flex-direction: column;
  }

  .text {
    @media (max-width: @screen-md) {
      margin-bottom: 8px;
      justify-content: center;
    }

    b {
      padding: 0 4px;
    }

    display: flex;
    align-items: center;
  }

  .btn__preview {
    color: #fff;
    margin-left: 8px;

    @media (max-width: @screen-md) {
      margin-bottom: 10px;
    }
  }
}

.form-widget {
  &__container {
    .ant-steps-item-title {
      // border-bottom: 1px solid #eee;
      padding: 0 0px !important;
      font-size: 15px;
      font-weight: bold;
      width: 50%;
      color: #747c87;
    }

    .ant-steps-item-tail {
      left: 8px !important;
      right: auto !important;
    }

    .ant-steps-item-icon {
      float: left !important;
      margin-right: 0px !important;
      margin-left: -7px !important;
    }

    .ant-steps-item-icon {
      right: 3px !important;
    }

    .ant-steps-item-description {
      padding: 10px 0px;
    }
  }

  .section__text {
    display: flex;
    align-items: center;
  }

  .icon__attachment {
    padding: 0 8px;
    color: @primary-color;
    font-size: 1.5em;
  }

  &__result {
    // border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px 0px;

    .table-w {
      width: 100%;

      tr {
        background-color: #f5f8fa;
        border-bottom: 8px solid #fff;

        td {
          padding: 10px 5px;
        }
      }

      &__action {
        text-align: left;

        button {
          margin: 0 4px;
        }
      }
    }
  }
}


.modal-code {
  .preview-area {
    min-height: 250px;
  }
}

.banners {
  // height: 80vh;
  overflow: auto;

  .ant-row {
    // margin: 0px !important;
  }

  .banners_div {
    overflow: hidden !important;
  }

  .banner_detail {
    // min-width: 364px;
    padding-top: 5px !important;
    position: relative;

    .ant-card-body {
      padding: 12px;
    }

    .ant-card {
      overflow: unset !important;
    }
  }
}

.banner_item {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: @screen-md) {
    flex-direction: column-reverse;
  }

  // font-size: 12px;
  .banner {
    &__right {
      flex-grow: 1;
      width: 50%;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: @screen-md) {
        width: 100%;
        text-align: center;
      }
    }

    &__left {
      width: 50%;
      flex-grow: 1;

      @media (max-width: @screen-md) {
        width: 100%;
      }

      .img {
        background-position: center;
        height: 140px;
        border-radius: 6px;
        margin-bottom: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #eee;

        &__skelton {
          width: 100%;
        }
      }
    }

    &__title {
      font-size: 1.1em;
      margin-top: 12px;
      margin-bottom: 5px;
      font-weight: bold;

      a {
        height: 25px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__tag {
      .ant-tag {
        border-radius: 10px;
        background: #fff;
      }
    }

    &__date {
      margin-top: 8px;
      font-size: 0.9em;

      ul {
        padding: 0;
        list-style-type: none;

        li {
          margin-bottom: 5px;
          span {
            margin-left: 5px;
            color: @gray-new;

            @media (max-width: @screen-md) {
              display: block;
            }
          }

          b {
            font-weight: normal;
            direction: ltr;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;

      @media (max-width: @screen-md) {
        display: block;
      }

      button {
        padding: 0 8px;
        // font-size: 12px;
        flex-grow: 1;

        &:first-child {
          margin-left: 14px;

          @media (max-width: @screen-md) {
            margin-bottom: 10px;
          }
        }
      }
    }

    &_cat {
      // background-color: #f3f5fa;
      // border-radius: 6px;
      padding: 5px;
      border-top: 1px solid #f3f5fa;

      .icon {
        color: @info-color;
        padding: 5px;
      }
    }
  }

  &_is-default {
    background-color: #ff4040;
    color: #fff;
    text-align: center;
    position: absolute;
    top: -5px;
    padding: 4px;
    left: 0px;
    width: 28px;
    height: 28px;
    z-index: 100 !important;
    border-radius: 10px;
    font-size: 14px;
    border-bottom-right-radius: 30px;
    display: none;
  }
}

.form-widget {
  &_banner {
    .title {
      padding-bottom: 10px;
      font-size: 1.2em;
      color: @info-color;
    }
  }

  &_results {
    .widget_li {
      margin-bottom: 10px;
    }
  }
}

.banner {
  .default_help {
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

.ltr_cls {
  .banner {
    .default_help {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}

.campaign {
  &-steps {
    background-color: #fff;
    padding: 10px;
  }
}

.disabled-card {
  // background-color: #e4e4e4;
  // opacity: 0.5;
  filter: grayscale(.9);
  .img {
    // filter: invert(40%) !important;
  }
}

.widget_search_custom{
  background-color: transparent !important;
  border: 1px solid #468bff !important;
  color: #468bff !important;
}

.widget_search_custom:hover{
  background-color: rgb(229, 238, 255) !important;
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;