@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
// @import 'assets/fonts/IRANSans.less';
@import "assets/fonts/Gsans.less";
// @import "assets/fonts/Yekan.less";
@import "assets/fonts/Yekan-bakh.less";

@import "app/theme.less"; // variables to override above

@import "assets/fonts/icon-kits/style.less";

html{
  font-feature-settings: normal !important;
  font-variant: normal !important;
}

body {
  font-family: IRANYekan !important;
  letter-spacing: -0.42px;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  font-variation-settings: "wght" 400, "dots" 1 !important ;
 
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl>input.ant-input{
  font-variation-settings: "wght" 400, "dots" 1 !important ;
}
.public__page {
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
}

.ant-card {
  // -webkit-box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  // -moz-box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
  // box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  -webkit-box-shadow: 0 2px 7px 0 rgba(34, 68, 112, 0.07);
  -moz-box-shadow: 0 2px 7px 0 rgba(34, 68, 112, 0.07);
  box-shadow: 0 2px 7px 0 rgba(34, 68, 112, 0.07);
  border-radius: 12px;
  overflow: hidden;
}

.app__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400vh;
}

.btn-status {
  font-weight: bold;
  border: 0;
  &__success {
    background-color: #eff9ed;
    color: #529b40;
  }
  &__danger {
    background-color: #fff0f0;
    color: #c33f3d;
  }
  &__pending {
    background-color: #f0f2f4;
    color: #474c53;
  }
  &__warning {
    background-color: #fdf2e6;
    color: #e98213;
  }
}

.kyc_alert {
  margin-bottom: 20px;
}
.ant-alert {
  padding: 16px 16px;
}

#formSearch {
  .ant-row {
    margin: 0px;
    // padding: 5px 0;
  }
  // .ant-form-item {
  //   margin: 0;
  //   padding: 8px 0;
  // }
  // .ant-input-affix-wrapper {
  //   margin: 8px 0;
  // }
  // .ant-form-item-control {
  //   // padding: 8px 0;
  // }
  // button[type="submit"] {
  //   margin: 8px 0;
  // }
}

#crisp-chatbox {
  font-family: "IRANYekan" !important;
  div,
  span {
    font-family: "IRANYekan" !important;
  }
  textarea {
    font-family: "IRANYekan" !important;
    direction: ltr !important;
  }
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 7%);
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 7%);
}

.flex {
  &-row {
    &__center {
      display: flex;
      align-items: center;
    }
  }
}
.pagination {
  margin-top: 20px;
  text-align: left;
  position: relative;

  @media (max-width: @screen-md) {
    text-align: right;
  }

  .ant-pagination-total-text {
    position: absolute;
    // bottom: 12px;
    padding-top: 2px;
    right: 6px;
    color: #747c87;
    @media (max-width: @screen-md) {
      position: static;
    }
  }
}

#crisp-chatbox a[href='https://crisp.chat/livechat/?ref=chatbox&domain=affilio.ir&name=Affilio']
{
  // display: none !important;
  // z-index: 0 !important;
}

.ant-notification-notice-close {
  right: 12px !important;
}

.app_main_error {
  .ant-message-notice-content {
    background-color: #474c53;
    color: #fff;
  }
}
.upgradeAlert {
  .upgradeTitle {
    color: @tag-blue-text;
    font-weight: 400;
  }
  .upgrade {
    margin-left: 5px;
  }
  .ant-modal-confirm-content {
    color: @dark-gray;
  }
}

.print-view {
  background-color: #fff;
  width: 1300px;
  margin: auto;
  direction: rtl;
  padding: 20px;
  border-radius: 10px;
  font-size: 12px;
  font-family: GoogleSans, IRANYekan !important;

  .form-search-bar {
    display: none;
  }
  .ant-pagination {
    display: none !important;
  }
  @media print {
    .print-view__print {
      display: none !important;
    }
  }
}

.ltr_cls {
  .ant-select-dropdown {
    text-align: left !important;
  }

  .ant-modal-confirm-title {
    text-align: left;
  }
}

.message_custom {
  top: unset !important;
  bottom: 20px !important;
  left: 20px !important;
}

.ant-message-error {
  // color: red;
  padding: 5px;
}

.icon-error {
  color: #c1c1c1 !important;
  font-size: 84px !important;
}

.code-area {
  direction: ltr;
  font-size: 0.9em;
  display: block;
  background-color: #f5f8fa;
  padding-top: 0px;
  overflow-y: auto;
  font-family: monospace;
}
.ant-modal.upgradeAlert{
  .ant-modal-content{
    overflow: hidden;
  }
  .ant-modal-confirm-btns{
    padding: 24px;
    padding-top: 0;
    margin-top: 0;
  }
  .release-title{
    color: @primary-color;
    font-size: 16px;
  }
}
.release-content{
  padding: 24px;
}
.change-logs {
  max-height: 300px;
  overflow-y: auto;
  &__head {
    margin-top: 8px;
  }
  &__body {
    margin-top: 10px;
    ul{
      padding: 0;
      padding-right: 20px;
      line-height: 30px;
      color: #474C53;
    }
    p {
      padding: 0px;
      margin: 0px;
    }
  }
}
// TODO Must fix error
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-table{
  font-feature-settings: normal !important;
  font-variant: normal !important;
}
.ant-card{
  font-feature-settings: normal !important;
  font-variant: normal !important;
}

.ant-alert{
  font-feature-settings: normal !important;
  font-variant: normal !important;
}
.ant-input{
  font-feature-settings: normal !important;
  font-variant: normal !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  border-color: transparent !important;
}

.table-c td, .table-c th{
  border-bottom: 1px solid #F0F1F6 !important ;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  background-color: #646b7a !important ;
}

.ant-form-item-required{
  direction: ltr;
}

.auth__actions button{
  font-weight: 400 !important;
}

.ant-btn-background-ghost.ant-btn-primary:hover{
  color: #ffffff !important;
}

.ant-table-cell{
  white-space: pre !important;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before{
  margin-right: 3px !important;
  margin-bottom: 5px !important;
}
.ant-select{
  font-feature-settings: normal !important;
  font-variant: normal !important;
}
.delete_filters{
  font-variation-settings: "wght" 400, "dots" 1 !important;
}
.ant-tabs-content-holder{
  font-variation-settings: "wght" 400, "dots" 1 !important;
}

.ant-btn{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }
 body{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }

 .root{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }

 .ant{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }
 .ant-btn .ant-btn-primary .ant-btn-lg .ant-btn-rtl{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }

 .ant-modal-body{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }
 .ant-form-item-control-input-content{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }

 .ant-input{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }

 .ant-input-rtl{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }
 .ant-picker{
  font-variation-settings: "wght" 400, "dots" 1 !important;
 }

 .ant-picker-input > input:placeholder-shown{
  font-variation-settings: "wght" 400, "dots" 1 !important;

 }
 .search_btn_modal{
  border : 1px solid #468bff !important;
  color : #468bff !important;
  float: right !important;

 }
 .search_btn_modal:hover{
  background-color: rgb(229, 238, 255) !important;
 }
 .modaaal_footer{
  margin-top: 50px !important;
 }
 .ant-modal{
  font-feature-settings: normal !important;
  font-variant: normal !important;
 }
 .ant-modal-wrap{
  z-index: 1000;
 }
 .ant-card-body {
  height: 100% !important;
 }

 .search_btn_suggest:hover{
  background-color: rgb(229, 238, 255) !important;
}
.search_btn_suggest{
  border: 1px solid #468bff !important;
  color: #468bff !important;
}
.cursor-pointer{
  cursor: pointer;
}
.selected-items-btn{
  margin: 0 15px;
  font-size: 14px;
  .count{
      margin-left: 5px;
      font-size: 12px;
      background-color: #468BFF;
      color: #fff;
      padding: 3px;
      border-radius: 7px;
      min-width: 24px;
  }
}
@primary-color-light-2: #E5EEFF;@text-text-body: #7d8793;@success-color: #009F60;@success-color-light: #58BF97;@success-color-dark: #007D4B;@success-color-text: #1E4620;@success-color-back: #E0F3EC;@warning-color: #ff9800;@warning-color-light: #ffb74d;@warning-color-dark: #f57c00;@warning-color-text: #663D00;@warning-color-back: #FFF0D9;@error-color: #f44336;@error-color-light: #e57373;@error-color-dark: #d32f2f;@error-color-text: #621B16;@error-color-back: #FDE3E1;@info-light: #60A2FF;@info-color-light: #60A2FF;@info-color-dark: #1054B4;@info-color-text: #09326B;@info-color-back: #E8F1FF;@grey-1: #f5f7fa;@grey-2: #f0f1f6;@grey-3: #dde1e6;@grey-4: #bcc4cc;@grey-5: #e4e7eb;